import React from "react";
// import { Link } from 'react-router-dom';
import { Row, Col, Card } from "react-bootstrap";

import PageTitle from "../../../layouts/PageTitle";
import Acc from "./Acc";
import Bfi from "./Bfi";
import Wom from "./Wom";
import Myb from "./Myb";

import Example from "./Example";
import YearPicker from "../../../layouts/custom/datePickerTahun";

import accImage from "../../../../images/natadana/acc.png";
import bfiImage from "../../../../images/natadana/bfi.png";
import womImage from "../../../../images/natadana/wom.jpg";
import mybImage from "../../../../images/natadana/maybank.jpg";
import bcafImage from "../../../../images/natadana/bcaf.png";
import batumbuImage from "../../../../images/natadana/batumbu.png";
import danaiImage from "../../../../images/natadana/danai.png";
import kmfImage from "../../../../images/natadana/kmf.png";
import BcafReportMonth from "./Bcaf";
import BatumbuReportMonth from "./Batumbu";
import DanaiReportMonth from "./Danai";
import KmfReportMonth from "./Kmf";

function Bulan() {
  return (
    <>
      <PageTitle motherMenu="Charts" activeMenu="Bulan" />
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              {/* <Row>
                <Col xl={6} lg={6}> */}
              {/* <h4 className="card-title">Acc Tahun</h4> */}
              <img src={accImage} className="rounded" alt="" />

              {/* </Col>
                <Col xl={6} lg={6}>
                  <YearPicker/>
                </Col>
              </Row> */}
            </Card.Header>
            <Card.Body>
              <Acc />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              {/* <h4 className="card-title">Bfi</h4> */}
              <img src={bfiImage} className="rounded" alt="" />
            </Card.Header>
            <Card.Body>
              <Bfi />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              {/* <h4 className="card-title">WOM</h4> */}
              <img
                src={womImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <Wom />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={mybImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <Myb />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={bcafImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <BcafReportMonth />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={batumbuImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <BatumbuReportMonth />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={danaiImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <DanaiReportMonth />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={kmfImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <KmfReportMonth />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Bulan;
